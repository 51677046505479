<template>
  <div class="change_bg">
    <Navbar></Navbar>
    <div class="container">
      <p class="site_title">{{$store.state.docs20.documents[0].title}}</p>
      <div class="monetoring_description" v-html="$store.state.docs20.documents[0].description">
      </div>

      <div class="dec_card monitor_card" v-for="item in $store.state.docs20.documents" :key="item">
        <div class="dec_card_main">
          <div class="loga_dec">

            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5671 38.0808H18.6812C19.3931 38.0808 19.916
              38.217 20.25 38.4895C20.5884 38.762 20.7576 39.1772 20.7576 39.7354C20.7576 40.1528 20.6675 40.489 20.4873 40.7439C20.3071 40.9988 20.0435 41.1855 19.6963 41.3042C19.3491 41.4185 18.9272 41.4756 18.4307 41.4756H17.5671V38.0808Z"
                    fill="url(#paint0_linear_1663_9833)"/>
              <path
                  d="M30.0916 39.5903C30.342 40.1001 30.4673 40.7505 30.4673 41.5415C30.4673 42.7148 30.1794 43.5981 29.6038 44.1914C29.0281 44.7847 28.158 45.0813 26.9934 45.0813H25.8728V38.0808H27.2241C27.9272 38.0808 28.5183 38.2061 28.9973 38.4565C29.4807 38.7026 29.8455 39.0806 30.0916 39.5903Z"
                  fill="url(#paint1_linear_1663_9833)"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.75 3.375C6.75 1.51104 8.26104 0 10.125 0H30.375V10.9688C30.375 13.2987 32.2638 15.1875 34.5938 15.1875H47.25V50.625C47.25 52.489 45.739 54 43.875 54H10.125C8.26104 54 6.75 52.489 6.75 50.625V3.375ZM21.509 37.5337C20.9377 37.0239 20.0303 36.769 18.7866 36.769H15.9851V46.4062H17.5671V42.7939H18.5955C19.2986 42.7939 19.8896 42.7104 20.3687 42.5435C20.852 42.3765 21.241 42.1501 21.5354 41.8645C21.8298 41.5745 22.0408 41.2427 22.1682 40.8691C22.3 40.4912 22.366 40.0935 22.366 39.676C22.366 38.7576 22.0803 38.0435 21.509 37.5337ZM31.5088 44.2178C31.9131 43.4883 32.1152 42.5808 32.1152 41.4954C32.1152 40.4626 31.9175 39.5969 31.522 38.8982C31.1265 38.1951 30.5662 37.6655 29.8411 37.3096C29.116 36.9492 28.2546 36.769 27.2571 36.769H24.2908V46.4062H26.967C28.0525 46.4062 28.9797 46.2239 29.7488 45.8591C30.5178 45.49 31.1045 44.9429 31.5088 44.2178ZM34.1917 46.4062H35.7605V42.4314H39.386V41.1064H35.7605V38.1006H39.6365V36.769H34.1917V46.4062Z"
                    fill="url(#paint2_linear_1663_9833)"/>
              <path
                  d="M32.0625 10.9688V0.253447C32.4108 0.39661 32.7355 0.598196 33.0216 0.852494L46.1172 12.4931C46.44 12.78 46.7009 13.1225 46.8906 13.5H34.5938C33.1958 13.5 32.0625 12.3667 32.0625 10.9688Z"
                  fill="url(#paint3_linear_1663_9833)"/>
              <defs>
                <linearGradient id="paint0_linear_1663_9833" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25"
                                gradientUnits="userSpaceOnUse">
                  <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                  <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1663_9833" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25"
                                gradientUnits="userSpaceOnUse">
                  <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                  <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1663_9833" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25"
                                gradientUnits="userSpaceOnUse">
                  <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                  <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                </linearGradient>
                <linearGradient id="paint3_linear_1663_9833" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25"
                                gradientUnits="userSpaceOnUse">
                  <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                  <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                </linearGradient>
              </defs>
            </svg>

          </div>
          <div class="title_dec">
            <div class="dec_title">
             {{item.document_number}}
            </div>
<!--            <div class="dec_num"> {{ new Date(item.published_date).toLocaleDateString() }}-->
<!--            </div>-->
          </div>

        </div>
        <div class="download">
          <a :href="item.file">
            <div class="link_docs">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" fill="#00B57F" fill-opacity="0.05"/>
                <path
                    d="M34.083 26.6668C34.083 26.2525 33.7472 25.9168 33.333 25.9168C32.9188 25.9168 32.583 26.2525 32.583 26.6668H34.083ZM15.417 26.6668C15.417 26.2525 15.0812 25.9168 14.667 25.9168C14.2528 25.9168 13.917 26.2525 13.917 26.6668H15.417ZM30.2783 22.8071C30.5712 22.5142 30.5712 22.0393 30.2783 21.7464C29.9854 21.4535 29.5106 21.4535 29.2177 21.7464L30.2783 22.8071ZM24.035 27.9898L23.5047 28.5201C23.6453 28.6607 23.8361 28.7398 24.035 28.7398C24.2339 28.7398 24.4247 28.6607 24.5653 28.5201L24.035 27.9898ZM18.8523 21.7464C18.5594 21.4535 18.0846 21.4535 17.7917 21.7464C17.4988 22.0393 17.4988 22.5142 17.7917 22.8071L18.8523 21.7464ZM24.785 14.6768C24.785 14.2625 24.4492 13.9268 24.035 13.9268C23.6208 13.9268 23.285 14.2625 23.285 14.6768H24.785ZM23.285 27.9728C23.285 28.387 23.6208 28.7228 24.035 28.7228C24.4492 28.7228 24.785 28.387 24.785 27.9728H23.285ZM32.583 26.6668V29.7138H34.083V26.6668H32.583ZM32.583 29.7138C32.583 30.9565 31.5758 31.9638 30.333 31.9638V33.4638C32.4042 33.4638 34.083 31.785 34.083 29.7138H32.583ZM30.333 31.9638H17.667V33.4638H30.333V31.9638ZM17.667 31.9638C16.4242 31.9638 15.417 30.9565 15.417 29.7138H13.917C13.917 31.785 15.5958 33.4638 17.667 33.4638V31.9638ZM15.417 29.7138V26.6668H13.917V29.7138H15.417ZM29.2177 21.7464L23.5047 27.4594L24.5653 28.5201L30.2783 22.8071L29.2177 21.7464ZM24.5653 27.4594L18.8523 21.7464L17.7917 22.8071L23.5047 28.5201L24.5653 27.4594ZM23.285 14.6768V27.9728H24.785V14.6768H23.285Z"
                    fill="#00B57F"/>
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/Header/Navbar";
import Footer from "@/components/Header/Footer";

export default {
  components: {
    Footer,
    Navbar
  },
  mounted() {
    this.$store.dispatch('docs20')
  },
}
</script>